import React from "react"
import { useStaticQuery, graphql } from "gatsby";

import SEO from "../components/seo"

import LayoutMedia from "../layouts/media"
import Main from "../components/main"
import PageHeader from "../components/chrome/pageHeader"
import PageFooter from "../components/chrome/pageFooter"
import Postteaser from "../components/post-teaser"
import Container from "../layouts/container"

import Breadcrumbs from "../components/chrome/breadcrumbs"

// eslint-disable-next-line no-unused-vars
import Styles from '../styles/styles.scss'

const PageIndex = () => {
    const data = useStaticQuery(
        graphql`{
            medias: allMarkdownRemark(
                filter: {fileAbsolutePath: {regex: "/(content/medias/)/"}}
                sort: {fields: [frontmatter___date], order: DESC}
            ) {
                totalCount
                edges {
                node {
                    id
                    frontmatter {
                    title
                    subtitle
                    date
                    dateModified
                    datePublished
                    tags
                    }
                    fields {
                    slug
                    }
                    excerpt
                }
                }
            }
        }`
    );

    const renderEntries = () => {
        console.log(data.medias)
        const entries = data.medias.edges?.map(entry => {
            const { node } = entry
            const { frontmatter } = node
            const { title } = frontmatter

            console.log(title)

            return <h2>{title}</h2>
        })

        return <Container>{entries}</Container >
    }

    return (
        <LayoutMedia>
            <SEO title="Media" />
            <PageHeader headerText="Per ardua ad astra" />

            <Main>

                <Breadcrumbs title="Media" path='/media' />

                <div className="row">
                    <div className="col--full">
                        <Container>

                            <Container>
                                <h1 className="h1">Media files</h1>
                            </Container>

                            {renderEntries()}

                        </Container>
                    </div>
                </div>
            </Main>

            <PageFooter />
        </LayoutMedia>
    )
}

export default PageIndex